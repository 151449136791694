@import '../../css/gj-strap-color';
@import '../../../../css/stylesheets/gj-strap-custom';

.tile-pagination{
    margin-top:25px;
}

.datatable-pager-nav {
    margin: 5px 0 10px 0;
    padding: 0;
    display: inline-block;
}
.datatable-pager-nav li {
    padding: 0;
    margin-right: 8px;
    display: inline-block;
}
.datatable-pager-link {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 2.25rem;
    min-width: 2.25rem;
    padding: 0.5rem;
    border-radius: 3px;
    position: relative;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 500;
    color: #93a2dd !important;
}
.datatable-pager-link.active {
    color: #fff !important;
    background: $pagination-link-bg-color;
}
.datatable-pager-link.disabled {
    color: #93a2dd;
    background: #f0f3ff;
    opacity: 0.3;
}
.datatable-pager-link:hover,
.datatable-pager-link.first:hover,
.datatable-pager-link.prev:hover,
.datatable-pager-link.next:hover,
.datatable-pager-link.last:hover,
.datatable-pager-link.disabled:hover {
    color: #fff !important;
    background: $pagination-link-bg-color;
    transition: all 0.3s;
}
.datatable-pager-link.next,
.datatable-pager-link.last {
    color: #93a2dd;
    background: #f0f3ff;
}
.datatable-pager-info {
    float: right;
    margin: 0;
}

.datatable-pager-info .ebp{
    width: 100px;
    display: inline-block;
    margin-right: 10px;
}