@import '../../css/gj-strap-color.scss';
@import '../../../../css/stylesheets/gj-strap-custom.scss';

.menulink-card{
    
    border:none;
    background:none;
    cursor: pointer;
}

.menulink-bg-icon{
    position: relative;
    display: inline-block;
    width: 150px;
    height: 150px;
    background-color:$menulink-bg-color;
    margin:auto;
    border-radius: 75px;
}

.menulink-icon{
    display: block;
    padding-top:35px;
    color:white;
    font-size:115px;
}

.menulink-notifications{
    position: absolute;
    top:0px;
    right:0px;
    display: inline-block;
    width: 40px;
    height: 40px;
    padding-top:10px;
    color:white;
    text-align: center;
    background-color:green;
    border-radius: 20px;
    font-weight: bold;
}