/* Main */

$main-color:#3959a9;
$main-hover-color:#4164be; //#4edfc6
$background-color: #f2f3f7;

/* Header */
$header-icon-color: $main-color;
$header-bg-color:#ffffff;

/* Menu Link */
$menulink-bg-color:$main-color;

/* Menu */

//$menu-width: 260px;

$menu-bg-color: #3959a9;

$menu-link-color:#eaeef7;


$menu-link-color-important:#A3A0FB;
$menu-link-svg-color:#9fb1de;

$menu-link-active-bg-color:#2b4380;
$menu-link-active-color:white;
$menu-link-border:white;

$menu-link-category-bg-color:#334f96;

$menu-link-hover-bg-color:#4164be;
$menu-link-hover-color:white;
$menu-link-svg-hover-color:white;


$menu-select-color:black;
$menu-select-bg-color:rgb(207, 207, 221);

/* Menu Mobile */
$menu-mobile-toggle-bg-color:$menu-bg-color;
$menu-mobile-bg-color:$menu-bg-color;

/* Pagination */
$pagination-link-bg-color:$menu-bg-color;

/* DayPicker */
$form-daypicker-color:$main-color;
$form-daypicker-hover-color:$main-hover-color;

/* Slider */
$form-slider-color:$main-color;

/* Bootstrap */

$theme-colors: (
    "primary":$main-color
)