/*.tile-header{
    display:inline-block !important;
}

.tile-header form{
    display: inline-block;
}*/

@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/list/main.css';

//Login
.checkbox-list .form-group .checkbox span{
    left:-2px;
}

.w-40 {
    width: 40% !important;
}

.w-30 {
    width: 30% !important;
}

.w-20 {
    width: 20% !important;
}

.userCircle{
    display:inline-block;
    background-color:grey;
    width: 25px;
    height: 25px;
    margin-right: 5px;
    color:white;
    text-align: center;
    border-radius: 12px;
    //padding-top: 3px;
    margin-top:5px;
}

.userCircle div{
    margin-top:3px;
}



.actionCheck{
    font-size: 25px;
    margin-right: 10px;
    cursor: pointer;
}

.projectsReference{
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
}

.projectsReference:first-of-type{
    margin-top: -10px;
}

.typeColorBar{
    //position: absolute;
    //top:10px;
    //left: -10px;
    z-index: 1000;
    display: inline-block;
    float: left;
    margin-right: 10px;
    width: 3px;
    height: 25px;
    margin-top:10px;
    background-color: transparent;
}

.actionColorBar{
    //position: absolute;
    //top:10px;
    //left: -10px;
    z-index: 1000;
    display: inline-block;
    float: right;
    margin-right:10px;
    width: 3px;
    height: 25px;
    margin-top:10px;
    background-color: transparent;
}

.projectColorBar{
    //position: absolute;
    //top:10px;
    //left: -10px;
    z-index: 1000;
    display: inline-block;
    float: right;
    margin-right:10px;
    width: 3px;
    height: 20px;
    margin-top:5px;
    background-color: transparent;
}
.projectColorBarLegend{
    border-left: 3px solid;
    font-size: 14px;
}

#popover-basic{
    max-width:none;
    width: 500px;
}

.iframePrint{
    position: absolute;
    border:none;
    width: 100%;
    height: 100%;
}

.link{
    cursor: pointer;
}

.select-hours-slider {
    margin: 60px 20px 25px;
}

.datatablespecial{
    display: content;
}