@import '../../css/gj-strap-color.scss';
@import '../../../../css/stylesheets/gj-strap-custom.scss';

/*start Calendar Daypicker*/
.date-picker {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.DayPicker_weekHeader_ul {
    border-bottom: 2px solid #efefef !important;
    padding-bottom: 10px;
}
.DayPickerNavigation_button__default {
    border: none !important;
}
.CalendarMonth_table {
    margin-top: 10px;
}
.CalendarDay__default {
    border: none !important;
}
.CalendarDay__default:hover {
    border: 1px double $form-daypicker-color;
    border-radius: 50%;
    color: inherit;
}
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: $form-daypicker-color !important;
    border-radius: 50%;
    color: #fff !important;
}
.CalendarDay__blocked_calendar, .CalendarDay__blocked_calendar:active, .CalendarDay__blocked_calendar:hover {
    background: #fff !important;
    border: 1px solid #cacccd;
    color: #cacccd  !important;
}
.noUi-horizontal .noUi-handle {
    width: 25px !important;
    height: 25px !important;
    top: -11px !important;
    border-radius: 50% !important;
}
.noUi-handle {
    border: 1px solid #4edfc6 !important;
    border-radius: 3px;
    background: #4edfc6;
    cursor: default;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
.noUi-handle:before {
    content: "";
    display: block;
    position: absolute;
    height: 9px;
    width: 9px;
    background: #E8E7E6 !important;
    left: 7px;
    top: 7px;
    border-radius: 50%;
}
.noUi-handle:after {
    display: none;
}
.noUi-horizontal {
    height: 5px;
}
.noUi-connect {
    background: #4edfc6;
  }
.noUi-tooltip {
    display: block;
    position: absolute;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    background: #fff;
    color: #000;
    padding: 5px 10px;
    text-align: center;
    white-space: nowrap;
    letter-spacing: 2px;
}
.noUi-target {
    background: #e6e6e6;
    border-radius: 4px;
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
  /*end Calendar Daypicker*/