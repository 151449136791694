/* Main */

$body-color:#646c9a;

$main-color:#ac1435; //#4edfc6
$main-hover-color:#d42349; //#4edfc6
$background-color: #f2f3f7; //#fff

/* Header */
$header-icon-color: $main-color;
$header-bg-color:#ffffff;

/* Menu Link */
$menulink-bg-color:$main-color;

/* Menu */

$menu-width: 260px;

$menu-bg-color: #aa1435; //#43425D

$menu-link-color:white;


$menu-link-color-important:#d42349; //#A3A0FB
$menu-link-svg-color:white; //#A5A4BF

$menu-link-active-bg-color:#87112c; //#3C3B54
$menu-link-active-color:white;
$menu-link-border:white; //#00D85A

$menu-link-category-bg-color:#9b1434; //#3C3B54

$menu-link-hover-bg-color:#d42349; //#3C3B54
$menu-link-hover-color:white;
$menu-link-svg-hover-color:white;

$header-menu-select-color:black; //black
$header-menu-select-bg-color: initial; //rgb(207, 207, 221);

$aside-menu-select-color:white; //black
$aside-menu-select-bg-color:#68061d; //rgb(207, 207, 221);

/* Menu Mobile */
$menu-mobile-toggle-bg-color:$menu-bg-color;
$menu-mobile-bg-color:$menu-bg-color;

/* Pagination */
$pagination-link-bg-color:$menu-bg-color;

/* DayPicker */
$form-daypicker-color:$main-color;
$form-daypicker-hover-color:$main-hover-color;

/* Slider */
$form-slider-color:$main-color;

/* Bootstrap */

$theme-colors: (
    "primary":$main-color
)
