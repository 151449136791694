@import '../../css/gj-strap-color';
@import '../../../../css/stylesheets/gj-strap-custom';

header {
    background-color: $header-bg-color;
    border: 1px solid #eaeaea;
    top: 0;
    right: 0;
    left: 0;
    z-index: 97;
    min-height: 60px;
    position: sticky !important;
}
header a span {
    font-size: 15px;
    color: #000000;
    font-weight: 700;
}
#subsribe-btn {
    width: 100px;
    color: #fff;
    background-color: #df4eb0;
    border-color: #df4eb0;
    border-radius: 0.8rem;
    font-weight: bold;
}
.header-menu-select{
    margin: 0;
    padding: 0px 30px;
    width: auto;
    background-color:$header-menu-select-bg-color;
    min-height: 60px;
    border-color: transparent;
    font-size: 15px;
    color: $header-menu-select-color;
    font-weight: 700;
}
.header-menu-select::-ms-expand.active {
    display: none;
}
.header-menu-select.active {
    -moz-appearance: none;
    -webkit-appearance: none;
}
nav ul {
    padding: 0;
    margin-top: 20px;
}
nav ul li {
    line-height: 50px;
    list-style: none;
}
nav ul li a {
    color: inherit;
    text-decoration: none;
}
#toggle-nav-btn {
    position: absolute;
    left: 0;
    color: #ffffff;
    background-color: $menu-mobile-toggle-bg-color;
    margin: 0;
    padding: 5px 15px;
    font-size: 20px;
    border-bottom-right-radius: 10px;
}
#aside-nav {
    position: fixed;
    top: 0;
    z-index: 999;
    height: 100%;
    width: 65%;
    max-width: 400px;
    text-align: center;
    padding: 30px;
    font-size: 18px;
    color: #ffffff;
    background: $menu-mobile-bg-color;
    box-shadow: -3px 0 10px rgba(0, 0, 0, 0.2);
}
#aside-nav:not(:target) {
    left: -100%;
    transition: left 1s;
}
#aside-nav:target {
    left: 0;
    transition: left 1s;
}
.navbar-nav-container {
    margin-left: auto;
    font-size: 13px;
    float:right;
}
#logo {
    height: 35px;
    border-radius: 50%;
    margin: 0px 5px;
}
.navbar-nav-scroll a{
    color:black;
    font-size: 18px;
}

#notification-icon{
    position: relative;
}
#notification-number{
    background: #5A80FB;
    border-radius: 50%;
    padding-top: 10px;
    margin-left: -26px;
    position: absolute;
    top: -5px;
    color: #fff;
    font-size: 11px;
    width: 20px;
    height: 20px;
    line-height: 0px;
}

@media (min-width: 1025px) {
    #toggle-nav-btn {
        display: none;
    }
}

@media (max-width: 1024px) {
    header {
        background-color: inherit;
        border: none;
        min-height: 0;
        /*position: inherit !important;*/
        background-color: white;
        margin-left: 0px;
    }

    #logo {
        height: 30px;
        margin-top: 2px;
    }
    .navbar {
        padding: 0;
    }

    .navbar-nav-container {
        position: absolute;
        top: 8px;
        right: 15px;
        font-size: 17px;
    }
    .brand-name {
        display: none;
    }
    .navbar-brand{
        margin: auto;
    }
}
