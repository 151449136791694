@import '../../css/gj-strap-color.scss';
@import '../../../../css/stylesheets/gj-strap-custom.scss';

.datatable{
    /*overflow-x: auto;*/
}

.loadingState{
    position: absolute;
    top:45%;
    left:45%;
    display: inline-block;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    background-color: white;
    opacity: 1;
}

.datatable-table{
    border-collapse: collapse;
    overflow: hidden;
    min-height: 300px;
    width: 100%;
    background-color: #fff;
    opacity: 1;
    transition: opacity 0.5s;
}

.datatable-table-pending{
    opacity: 0.2;
}

.datatable-table-small{
    min-height: 0px;
    margin-top: -15px;
    margin-bottom: -15px;
}

.datatable-body{
    visibility: visible;
    display: block;
    position: relative;
}
.datatable-head{
    visibility: visible;
    display: block;
    position: relative;
}
.datatable-row{
    display: table;
    table-layout: initial;
    width: 100%;
    border-bottom: 1px solid #f0f3ff;
}

.datatable-row-showMore{
    border-bottom:none;
}

.datatable-row:last-child{
    border-bottom:none;
}

.datatable-row .datatable-cell{
    overflow: hidden;
}

.datatable-row .datatable-cell span{
    display: block;
    overflow: hidden;
	text-overflow: ellipsis;
}

.datatable-head .datatable-row .datatable-cell span{
	display:inline-block;
}

.datatable-row .datatable-cell .click{
    cursor: pointer;
}

.datatable-row .datatable-cell .way{
    margin: 0px 6px;
    position: relative;
    top: -5px;
}

.greyLine{
    background-color: rgb(240, 240, 240);
}


.datatable-row span{
    /*width: 100px;*/
}

.datatable-body .datatable-row:hover{
    
}

.datatable-body .datatable-row-hover:hover{
    cursor: pointer;
    background-color:#fbfbfb !important;
}

.datatable-cell:first-child span{
    /* width: 30px; */
}

@media (max-width: 1200px){
    .datatable-cell span{
        width:100px;
    }
}

.datatable-cell{
    padding: 16px 5px;
    vertical-align: middle;
    font-size: 1rem;
}
.datatable-cell:first-child{
    /* padding-left: 25px; */
}
.datatable-cell.center{
    /* text-align: center; */
}
.datatable-cell .user{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    margin: 0;
}
.datatable-cell .user-pic{
    padding-right: 0.5rem;
}
.datatable-cell .user-pic img{
    border-radius: 50%;
    max-width: 40px;
    max-height: 40px;
}
.datatable-cell .user-details {
    line-height: 0;
}
.datatable-cell .user-firstname {
    display: block;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    color: #646c9a;
}
.datatable-cell .user-lastname {
    display: inline-block;
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 400;
    color: #a1a8c3;
}
.datatable-cell .badge {
    margin: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 0.8rem;
    color: #fff;
    background: #00D85A;
    height: auto;
    width: fit-content;
    padding: 5px 15px;
    border-radius: 2rem;
    font-weight: 300;
}
.datatable-cell .dropdown{
    display: inline-block;
}
.dropdown .btn-actions{
    display: inline-block;
    padding: 4px 6px;
    margin:0px 2px;
    font-size: 13px;
    line-height: 1.5;
    border-radius: .2rem;
    cursor: pointer;
}

.dropdown p:hover{
    background-color:$pagination-link-bg-color;
    color:white;
}