.subdisplay{
    display: inline;
    width: 100%;
}

.subdisplay button{
    opacity: 0;
}

.subdisplay:hover button{
    opacity: 1;
}

.inlineChoice{
    display:inline-block;
    width: 200px;
}

div.treeview{
    white-space: normal !important;
}