@import 'gj-strap-color';
@import '../../../css/stylesheets/gj-strap-custom';

@import '~bootstrap/scss/bootstrap.scss';


@font-face {
    font-family: 'Montserrat';
    src: url('../../../css/fonts/Montserrat-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('../../../css/fonts/OpenSans-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins';
    src: url('../../../css/fonts/Poppins-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    color: $body-color;
    background-color: $background-color;
}
html,
body {
    font-family: 'Poppins', sans-serif;
    height: 100%;
    margin: 0px;
    padding: 0px;
    font-size: 13px;
    font-weight: 300;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
html a:hover,
html a:active,
html a:focus,
body a:hover,
body a:active,
body a:focus {
    text-decoration: none !important;
}
html a,
html button,
body a,
body button {
    outline: none !important;
}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
      position: relative;
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
}

@media (min-width: 769px) and (max-width: 1024px) {
    html,
    body {
        font-size: 12px;
    }
}

@media (max-width: 767px) {
    html,
    body {
        font-size: 12px;
    }
}

#root{
    //position: relative;
    //min-height: 100%;
}

#arrow-down {
    vertical-align: bottom;
    margin-left: 5px;
}
h4 {
    text-align: center;
    margin-bottom: 30px;
    color: #ffffff;
}
.content {
    padding: 25px;
    padding-top: 0;
    overflow: hidden;
    min-height: calc(100vh - 110px);
}

@media (min-width: 1200px){
    .content .container{
        max-width: 960px;
    }
}

@media (max-width: 1024px){
    .content{
        min-height: calc(100vh - 75px);
    }
}

.content .container{

}
#notification-icon{
    font-size: 16px;
}
#account-icon{
    font-size: 16px;
}
#account-icon span{
    display: inline-flex;
    font-size: 15px;
    font-weight: 300;
}
#logout-icon{
    font-size: 16px;
}

#notification-icon, #account-icon, #logout-icon {
    color:$header-icon-color;
}

@media (max-width: 1024px) {
    .home{
        padding-left: 0px !important;
    }
    #notification-icon{
        padding-top: 0;
    }
    #account-icon{
        padding-top: 0;
    }
    #logout-icon{
        padding-top: 0;
    }
    #account-icon span{
        display: none;
    }
}
.home{
    padding-left: 260px;
}

#btn-hollow {
    border-color: #4edfc6;
    border-radius: 2rem;
    background-color: rgba(78, 223, 198, 0.05);
    box-shadow: 0px 4px 8px 0px rgba(78, 223, 198, 0.25) !important;
    font-size: 15px;
    padding: 15px 25px;
}

#btn-solid {
    color: #ffffff;
    border-color: #4edfc6;
    border-radius: 2rem;
    background-color: rgba(78, 223, 198, 1);
    box-shadow: 0px 4px 8px 0px rgba(78, 223, 198, 0.25) !important;
    font-size: 15px;
    padding: 15px 25px;
}
#btn-solid:hover {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(78, 223, 198, 0.25) !important;
    box-shadow: 0px 9px 16px 0px rgba(78, 223, 198, 0.25) !important;
    background-color: rgb(106, 237, 215) !important;
    border-color: rgb(106, 237, 215) !important;
}
#btn-solid-blue {
    color: #ffffff;
    border-color: #5A80FB;
    border-radius: 2rem;
    background-color: rgba(90, 128, 251, 1);
    box-shadow: 0px 4px 8px 0px rgba(90, 128, 251, 0.25) !important;
    font-size: 15px;
    padding: 15px 25px;
}
#btn-solid-blue:hover {
    -webkit-box-shadow: 0px 9px 16px 0px rgba(90, 128, 251, 0.25) !important;
    box-shadow: 0px 9px 16px 0px rgba(90, 128, 251, 0.25) !important;
    background-color: rgb(102, 137, 252) !important;
    border-color: rgb(102, 137, 252) !important;
}
#btn-solid-blue-active {
  //basic btn-solid-blue
  color: #ffffff;
  border-radius: 2rem;
  font-size: 15px;
  padding: 15px 25px;

  //modification
  -webkit-box-shadow: 0px 9px 16px 0px rgba(90, 128, 251, 0.25) !important;
  box-shadow: 0px 9px 16px 0px rgba(90, 128, 251, 0.25) !important;
  background-color: rgb(78, 116, 223) !important;
  border-color: rgb(78, 116, 223) !important;
}

.checkbox {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    font-size: 1.1rem;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.checkbox > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.checkbox-list .checkbox {
    text-align: left;
    display: block;
}
.checkbox > span {
    border: 1px solid #d1d7e2;
    border-radius: 3px;
    background: none;
    position: absolute;
    top: 1px;
    left: 0;
    height: 20px;
    width: 20px;
}
.checkbox > input:checked ~ span:after {
    display: block;
}
.checkbox > span:after {
    border: solid #5a80fb;
    content: '';
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    margin-left: -2px;
    margin-top: -6px;
    width: 5px;
    height: 10px;
    border-width: 0 2px 2px 0 !important;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.form-group{
    position: relative;
    margin-top:0.5rem;
    margin-bottom:0.5rem;
}
.form-group .mb-3{
    margin-bottom: 0rem !important;
}
.form-group label{
    letter-spacing: 1px;
    font-weight: 400;
}
.signup{
    margin: auto;
    display: table;
    font-size: 14px;
}
.signup-link{
    padding-left: 10px;
}
.form-input-error-message{
    position: absolute;
    top: 0;
    right: 0;
}
#label1 {
    font-family: 'Open Sans';
}
#input1 {
    border-color: #e0e7ff;
}
input::placeholder {
    color: #b0bac9;
}
:-ms-input-placeholder, ::-ms-input-placeholder {
}

.form-simple-data{
    margin-top:0.5rem;
    margin-bottom:0.5rem;
}

.badge{
    font-size: 100%;
    font-weight: normal;
    color:white;
    padding: 0.65rem 1rem;
    margin-right: 5px;
}

#step1 {
}
#container1-step1 {
    justify-content: center;
    margin: auto;
}
#title1 {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 50px;
    font-weight: 400;
    color: #434349;
}
#title2 {
    margin-top: 30px;
    margin-bottom: 35px;
    color: #2e384d;
    font-weight: 400;
}
#title3 {
    margin-top: 30px;
    font-size: 16px;
    font-weight: bold;
  }
  .text-pink {
    color: #df4eb0;
  }
  .text-green {
    color: #4edfc6;
  }
  .text-purple {
    color: #7e4edf;
  }
  #search-container {
    margin-bottom: 70px;
  }
  #search-address {
    width: 365px;
  }
  @media (max-width: 767px) {
    #search-address {
      width: 290px;
    }
  }
  #image1 {
    display: flex;
    justify-content: center;
  }
  #btnSearch-step1 {
    width: 150px;
    color: #ffffff;
    background-color: #df4eb0;
    border: #d5d5d5;
    font-size: 20px;
  }
  #btn-nursery {
    border-color: #4edfc6;
    border-radius: 2rem;
    background-color: rgba(78, 223, 198, 0.05);
    font-size: 15px;
  }
  #btn-pro {
    border-color: #7540ee;
    border-radius: 2rem;
    background-color: rgba(117, 64, 238, 0.05);
    font-size: 15px;
  }
  #blue-link {
    color: #3b86ff;
    font-size: 13px;
    font-weight: 400;
    margin: auto;
    padding: 10px 0 10px 0;
    text-decoration: underline;
    cursor: pointer;
  }
  .answering-container {
    margin-top: 120px;
    margin-bottom: 35px;
  }
  .answering-image {
    width: 80px;
    position: absolute;
    right: 0;
    margin-top: -95px;
    margin-right: 20px;
  }
  #step2 {
    background-color: #3b86ff;
  }
  #star-green {
    color: #4edfc6;
    font-size: 10px;
    margin-right: 10px;
  }
  #star-purple {
    color: #7540ee;
    font-size: 10px;
    margin-right: 10px;
  }
  #discover1 {
    padding: 40px;
    justify-content: center;
    text-align: center;
    height: 400px;
    position: relative;
  }
  .discover-btn {
    position: absolute;
    bottom: 30px;
    width: fit-content;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .discover-btn h5 {
    margin: 0;
    padding-bottom: 10px;
    font-size: 14px;
  }
  .discover-btn .underline1 {
    border-bottom: 3px solid #4edfc6;
  }
  .discover-btn .underline2 {
    border-bottom: 3px solid;
    border-image: linear-gradient(to right, #4edfc6 50%, #7e4edf 50%);
    border-image-slice: 1;
  }
  .discover-btn .underline3 {
    border-bottom: 3px solid #7e4edf;
  }
  .portlet {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
    background-color: #ffffff;
    margin-bottom: 20px;
    border-radius: 4px;
  }
  .portlet .portlet-head {
  }
  .portlet .portlet-body {
    padding: 15px 25px;
    position: relative;
  }
  .portlet .portlet-foot {
  }
  .portlet .portlet-author {
  }
  .portlet-height-fluid {
    height: calc(100% - 20px);
  }
  @media (min-width: 1025px) {
    .portlet-height-fluid {
      height: calc(100% - 20px);
    }
    .portlet-height-fluid .portlet-body {
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
    }
  }

  @media (max-width: 1024px) {
    .portlet {
      margin-bottom: 10px;
    }
  }


  /*start Aside menu*/
  #wrap {
    line-height: 1.6;
    margin: 0;
    display: flex;
    transition: transform .4s cubic-bezier(.25, .1, .25, 1);
  }
  #wrap:not(:target) {
    transform: translate3d(-200px, 0, 0);
  }
  #wrap:target {
    transform: translate3d(0, 0, 0);
  }
  #open,
  #close {
    height: 44px;
    text-align: right;
    display: block;
    margin-right: 15px;
    position: absolute;
    right: 0;
  }
  #wrap:target #open,
  #wrap:not(:target) #close {
    display: none;
  }
  #wrap:target #open {
    display: none;
  }

  .main {
    padding: 25px;
    flex: 1;
    display:inline-block;
  }
  @media (min-width: 1025px) {

  }
  @media (max-width: 1024px) {
      #wrap:not(:target) {
      transform: none;
      }
      .header {
       display: none;
      }
      #wrap {
        display: block;
      }
  }
  /*end Aside menu*/

  /*start Large button*/
  .btn-large {
    display: flex;
    -webkit-box-shadow: 0px 5px 12px 0px rgba(82, 63, 105, 0.2);
    box-shadow: 0px 5px 12px 0px rgba(82, 63, 105, 0.2);
    background-color: #ffffff;
    margin-bottom: 50px;
    border-radius: 4px;
    color: #8ba6f3;
    max-width: 500px;
  }
  .btn-large:hover {
    -webkit-box-shadow: 0px 5px 12px 0px rgba(82, 63, 105, 0.3);
    box-shadow: 0px 5px 12px 0px rgba(82, 63, 105, 0.3);
    background-color: #ffffff;
    color: #8ba6f3;
  }
  .btn-large-body {
    padding: 15px 65px;
    min-width: 100%;
    text-align: center;
  }
  .btn-large-content{
    display: flex;
    justify-content: space-between;
  }
  .btn-large-content p{
    margin: 0;
  }
  .btn-large-icon{
    margin-right: -40px;
  }
  .btn-large-icon i{
    font-size: 45px;
    /*background: #d6e0ff;*/
    padding: 20px;
    color: #0E65E5;
  }
  .btn-large-title{
    align-self: center;
    font-size: 18px;
    font-weight: 700;
  }
  #btn-large-green{
    background: #4EDFC6;
    color: #ffffff;
    box-shadow: 0px 5px 12px 0px rgba(78, 223, 198, 0.5);
  }
  #btn-large-orange{
    background: #fca962;
    color: #ffffff;
    box-shadow: 0px 5px 12px 0px rgba(252, 169, 98, 0.5);
  }
  #btn-large-red{
    background: #df4e4e;
    color: #ffffff;
    box-shadow: 0px 5px 12px 0px rgba(223, 78, 78, 0.5);
  }
  #btn-large-green:hover {
    -webkit-box-shadow: 0px 5px 12px 0px rgba(68, 188, 170, 0.8);
    box-shadow: 0px 5px 12px 0px rgba(68, 188, 170, 0.8);
    color: #ffffff;
  }
  #btn-large-orange:hover {
    -webkit-box-shadow: 0px 5px 12px 0px rgba(242, 129, 78, 0.8);
    box-shadow: 0px 5px 12px 0px rgba(242, 129, 78, 0.8);
    color: #ffffff;
  }
  #btn-large-red:hover {
    -webkit-box-shadow: 0px 5px 12px 0px rgba(213, 38, 58, 0.8);
    box-shadow: 0px 5px 12px 0px rgba(213, 38, 58, 0.8);
    color: #ffffff;
  }
  #btn-large-green .btn-large-icon i,
  #btn-large-orange .btn-large-icon i,
  #btn-large-red .btn-large-icon i{
    background: #ffffffd4;
  }
  @media (max-width: 767px) {
    .btn-large-body {
      padding: 15px 65px 15px 50px;
  } }
  /*end Large button*/

  /* calendar */

  /*start Profile*/
  #container-profile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    /*-webkit-box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.15);
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.15);*/
    background-color: #ffffff;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 0px;
  }
  #avatar-user{
    margin-bottom: 40px;
  }
  #avatar-user .avatar {
    margin: auto;
    margin-top: 50px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    line-height: 120px;
  }
  #avatar-user p {
    text-align: center;
    padding: 15px;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
  }
  .select-child {
    padding-bottom: 10px;
    scrollbar-width: thin;
    scrollbar-color: #d8dce6 #ebebeb;
  }
  .select-child::-webkit-scrollbar-thumb {
    background: #d8dce6;
    -webkit-border-radius: 1ex;
  }
  .select-child::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #ebebeb;
  }
  .separator-profile {
    border: 2px solid #ebebeb;
    width: 90%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 30px;
    display: flex;
  }
  #form-profile label{
    color: #B0BAC9;
    text-transform: uppercase;
    letter-spacing: 2x;
  }
  /*end Profile*/

  /*start Search Results*/
  .structures-list{
    list-style: none;
    padding: 0;
    max-width: 400px;
  }
  .structures-list li{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
   -webkit-box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.15);
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.15);
    background-color: #fff;
    position: relative;
    margin-bottom: 20px;
    border-radius: 5px;
    padding: 20px;
    border: 1px solid #efefef;
  }
  .structures-list li .icon{
    position: absolute;
    right: 25px;
  }
  .structures-list li .icon svg{
    height: 30px;
    width: 30px;
  }
  #avatar-searchResults .avatar{
    width: 100%;
    height: 160px;
    line-height: 160px;
    border-radius: 3px;
    /*float: left;*/
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -20px;
    padding-right: 40px;
    border: none;
  }
  #name-searchResults{
    margin: 10px 0;
  }
  #name-searchResults p{
    font-size: 16px;
  }
  #name-searchResults .name{
    font-weight: bold;
  }
  #success-searchResults{
    color: #4EDFC6;
    font-size: 16px;
    margin: auto;
    margin-bottom: 15px !important;
    margin-top: 15px !important;
  }
  #warn-searchResults{
    color: #f44336;
    font-size: 13px;
    margin: auto;
  }
  #results-container{
    margin-top: 30px;
  }
  #results-container p.center{
    font-size: 16px;
    margin-top: 20px;
  }
  /*end Search Results*/

  /*start Structure Profile*/
  #structureProfile-container{
    position: relative;
  }
  #avatar-structureProfile {
    margin-bottom: 10px;
  }
  #avatar-structureProfile .avatar{
      margin: auto;
      margin-top: 50px;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      line-height: 120px;

  }
  #structureProfile-container .type,
  #structureProfile-container .name {
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 1px;
  }
  #structureProfile-container .name{
    margin-bottom: 20px;
  }
  #form-structureProfile{
    padding-left: 30px;
    margin-bottom: 25px;

  }
  #form-structureProfile i{
    color: #cfd8dc;
    font-size: 20px;
    position: absolute;
    left: 0;
  }
  #form-structureProfile label{
    font-size: 16px;
    font-weight: 700;
  }
  .form-control-3{
    font-size: 15px;
    font-weight: 400;
  }
  #structureProfile-container .icon{
    position: absolute;
    top: 0;
    right: 20%;
  }
  #structureProfile-container .icon svg{
    height: 40px;
    width: 40px
  }
  /*end Structure Profile*/

  /*start Notifications*/
  .notification-list{
    margin-top: 30px;
    padding: 0;
  }
  .notification-list li{
    display: block;
    padding: 1.1rem 1.5rem;
    border-bottom: 2px solid #f7f8fa;
  }
  .notification-list li:hover {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-decoration: none;
    background-color: #f7f8fa;
  }
  .notification-list p{
    margin: 0;
  }
  .notification-list .timer{
    font-size: 1rem;
    font-weight: 400;
    color: #a1a8c3;
  }
  .container-info .structure-type{
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 500;
    color: #5365c7;
  }
  /*end Notifications*/

  /*start Historic*/
  .historic-list{
    margin-top: 30px;
    padding: 0;
  }
  .historic-list li{
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    padding: 1.1rem 1.5rem;
    border-bottom: 2px solid #f7f8fa;
  }
  .historic-list li:hover {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-decoration: none;
    background-color: #f7f8fa;
  }
  .historic-list p{
    margin: 0;
  }
  .historic-list .timer{
    font-size: 1rem;
    font-weight: 400;
    color: #a1a8c3;
  }
  .historic-img{
    display: flex;
    align-items: center;
  }
  /*end Historic*/

  /*start Planning*/
  .fc{
    margin: 30px 0;
  }
  .fc-toolbar.fc-header-toolbar {
    padding: 0 20px;
  }
  .fc-button-group{
    color: #535353;
    border-color: transparent;
    border-radius: 2rem;
    background-color: transparent;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 13px;
    padding: 0;
  }
  .fc-button-group button{
    /*padding: 15px 10px;*/
  }
  .fc-button-primary {
    background-color: transparent;
    border: 1px solid;
    color: #535353;
  }
  .fc-button-primary:hover {
    background-color: transparent !important;

    color: #535353 !important;
  }
  .fc-button-primary:disabled {
    background-color: transparent;
    border-color: transparent;
    color: #9c9c9c !important;
    border: 1px solid;
  }
  .fc-button-primary:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .fc-dayGridMonth-button{
    padding-left: 20px !important;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
  }
  .fc-timeGridDay-button{
    padding-right: 20px !important;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
  }
  .fc-button-primary:not(:disabled):active, .fc-button-primary:not(:disabled).fc-button-active {
    background-color: transparent;
    border-color: transparent;
    color: #9c9c9c !important;
    border: 1px solid;
  }
  .fc-button-primary:not(:disabled):active:focus, .fc-button-primary:not(:disabled).fc-button-active:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .fc-prev-button{
    padding-left: 20px !important;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
  }
  .fc-next-button{
    padding-right: 20px !important;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
  }
  .fc-scroller {
    scrollbar-width: thin;
    scrollbar-color: #d8dce6 #ebebeb;
    height: 100% !important;
  }
  .fc-scroller::-webkit-scrollbar-thumb {
    background: #d8dce6;
    -webkit-border-radius: 1ex;
  }
  .fc-scroller::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #ebebeb;
  }
  .fc-content{
    color: #fff;
  }
  .fc-content .fc-time{
    font-size: 12px;
  }
  .fc-slats tr{
    border: 0 hidden transparent;
  }
  .fc-view table{
    border: 0 hidden transparent;
    border-collapse: separate;
    border-spacing: 10px 2px;
  }
  .fc-unthemed td{
    border: transparent;
  }
  .fc-unthemed td.fc-today {
    background: #4edfc6ad;
  }
  .fc-row th{
    border: transparent !important;
    padding-bottom: 5px;
    color: #70769a;
  }
  .fc-day{
    background: rgba(165, 164, 191, 0.06);
    border: 1px solid rgba(163, 160, 251, 0.15) !important;
    border-radius: 5px;
  }
  .fc-unthemed td.fc-past {
    background: rgba(90, 89, 109, 0.15);
  }
  .fc-unthemed .fc-divider {
    display: none;
  }

  @media (max-width: 767px) {
    .fc-toolbar {
      font-size: 10px;
    }
    .fc-toolbar h2 {
      font-size: 12px;
    }
    .fc-toolbar div{
      margin-bottom: 5px;
    }
    .fc-toolbar.fc-header-toolbar {
      padding: 0;
    }
    .fc-button-group{
      font-size: 10px;
    }
    .fc-center{
      margin-right: 5px;
    }
    .fc-left{
      text-align: center;
    }
    .fc-view table{
      border-spacing: 2px 0;
    }
    .fc-prev-button {
      padding-left: 5px !important;
    }
    .fc-next-button {
      padding-right: 5px !important;
    }
    .fc-dayGridMonth-button{
      padding-left: 5px !important;
    }
    .fc-timeGridDay-button{
      padding-right: 5px !important;
    }
    .fc-scroller{
      height: 100%;
  } }
  /*end Planning*/

  .multipanel .progressBar{
    color:#fff;
    background-color:$menu-link-hover-bg-color;
    border-radius:20px;
    margin:-25px 0px 40px 0px;
  }

  .multipanel .progressBarStep{
    border-radius:20px;
    padding:10px 20px;
    text-align:center;
  }

  .multipanel .progressBarStepPresent{
    background-color:$menu-link-active-bg-color;
  }

  .multipanel .progressBarStepPast{
    background-color:$menu-link-hover-bg-color;
  }

  .multipanel .progressBarStepFutur{
    background-color:$menu-link-hover-bg-color;
  }

  .multipanel #btn-solid{
    margin:10px 10px;
  }
