@import '../../css/gj-strap-color.scss';
@import '../../../../css/stylesheets/gj-strap-custom.scss';

.form-control-2 {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ebedf2;
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 0;
    padding-left: 7px;
}
.form-control-2:focus {
    border-color: $main-color;
    outline: none;
}

.form-group span{
    position:absolute;
    top:10px;
    left:10px;
}

.form-group .search{
    border-radius: 5px;
    padding-left: 30px;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
}