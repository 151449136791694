@import '../../css/gj-strap-color';
@import '../../../../css/stylesheets/gj-strap-custom';

.menu {
    background: $menu-bg-color;
    width: $menu-width;
    vertical-align: top;
    //min-height: 100vh;
    margin-top: 0px;
    padding-top: 0px;
    float: left;
    top: 0px;
    height: 300px;
}
.menuFixed{
    position: fixed;
    top: 0;
    bottom:0;
    overflow-y:auto;
    background: $menu-bg-color;
    width: $menu-width;
    margin-top: 0px;
    padding-top: 0px;
    vertical-align: top;
    min-height: 100vh;
    //top:0px;
}
.menu-list{
    margin: 0;
    list-style: none;
    padding: 0px 0;
    width: 100%;
}
.menu-list li{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin: 0;
}
/*
.menu-list li:nth-child(even) a,
.menu-list li:nth-child(even) i{
    color: $menu-link-color-important;
}
.menu-list li:nth-child(even) a:hover span{
    color: $menu-link-color-important;
}*/
.menu-link{
    padding: 9px 30px 9px 28px;
    cursor: pointer;
    height: 44px;
    display: flex;
    flex-grow: 1;
    align-items: stretch;
    margin: 0;
}
.menu-link.active, .menu-link.active:hover{
    color: $menu-link-active-color !important;
    border-left: 4px solid $menu-link-border;
    background-color: $menu-link-active-bg-color;
}
.menu-link.active i{
    color: $menu-link-border !important;
}

.menu-link svg{
    margin-top: 5px;
    color: $menu-link-svg-color;
    display: flex;
    align-items: center;
    line-height: 0;
    flex: 0 0 30px;
    font-size: 1.3rem;
}

.menu-link:hover{
    color: $menu-link-hover-color;
    background-color: $menu-link-hover-bg-color;
}
.menu-link:hover i{
    color: $menu-link-hover-color;
}
.menu-link:hover span{
    color: $menu-link-hover-color;
}
.menu-link:hover svg{
    color: $menu-link-svg-hover-color;
}
.menu-link.active:hover svg{
    color: $menu-link-svg-color;
}
.menu-link span{
    display: flex;
    align-items: center;
    flex-grow: 1;
    line-height: 0;
    margin-left: 7px;
}
.aside-menu-select{
    margin: 0;
    padding: 0px 30px;
    width: 100%;
    background-color:$aside-menu-select-bg-color;
    min-height: 60px;
    border-color: transparent;
    font-size: 15px;
    color: $aside-menu-select-color;
    font-weight: 700;
}
.aside-menu-select::-ms-expand.active {
    display: none;
}
.aside-menu-select.active {
    -moz-appearance: none;
    -webkit-appearance: none;
}

.nav{
    font-size: 1rem;
}

.nav a, .nav .accordion .btn{
    color: $menu-link-color;
    text-decoration: none;
}

.menu-link-container .btn, .menu-link-container .btn:active, .menu-link-container .btn:focus{
    border:none;
    width: 100%;
    background:none;
    -webkit-tap-highlight-color:none;
    box-shadow:none;
    /*-webkit-transition:none;
    transition: none;*/
    outline: none !important;
}

.menu-link-container .btn span{
    margin-top: 6px;
}

.menu-sublinks{
    background-color: $menu-link-category-bg-color;
}

.menu-sublink{
    padding-left: 50px;
}

.menu-sublink.active, .menu-sublink.active:hover{
    padding-left: 46px;
}

.menu-category-arrow{
    position: relative;
    left:20px;
}

.menu-category-arrow-active{
    transform: rotate(-90deg);
}

.logoAside {
    height: 50px !important;
    margin: 10px 20px !important;
}

@media (max-width: 1024px) {
    .menu {
        display: none;
    }
}
