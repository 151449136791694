.fc-day-grid-event{
    //background-color: red;
    background: none;
    border:none;
}

.fc-day-grid-event .userAvatar{
    position: relative;
    top: -9px;
    cursor: pointer;
}