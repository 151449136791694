@import '../../css/gj-strap-color.scss';
@import '../../../../css/stylesheets/gj-strap-custom.scss';

.DateInput_input__focused{
    border-bottom: 2px solid $form-daypicker-color;
}

.CalendarDay__selected_span, .CalendarDay__hovered_span{
    background: $form-daypicker-color !important;
    color: #fff !important;
}

.CalendarDay__selected_start{
    border-top-right-radius: 0% !important;
    border-bottom-right-radius: 0% !important;
}

.CalendarDay__selected_end{
    border-top-left-radius: 0% !important;
    border-bottom-left-radius: 0% !important;
}

.CalendarDay__selected_span:hover{
    border-radius: 0% !important;
    background-color:$form-daypicker-hover-color !important;
}